<template>
  <div class="section-content pt-0 pr-0">
    <v-row v-if="level == 'Property'" no-gutters class="mt-n2">
      <HbPropertySelector
        v-show="!isOPS"
        id="facility"
        :items="facilityList"
        item-text="name"
        item-value="id"
        v-validate="'required'"
        v-model.lazy="property_id"
        data-vv-scope="gate"
        data-vv-name="facility"
        data-vv-as="Property"
        :error="errors.has('gate.facility')"
      />
    </v-row>

    <div class="primary-section-content mr-6" :class="{ 'pt-1' : accountingToggleOn && showIncomeAccountAlert && level === 'Corporate' }">
      <!-- Added by BCT: Added button above the notifiction message in OPS-->
      <div v-if="isOPS" class="ops-add-product-btn mb-5">
        <hb-btn class="float-right" @click="showAdd = true" color="secondary">Add New Product</hb-btn>
      </div>
      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
     
      <hb-notification
        v-model="showIncomeAccountAlert"
        v-if="accountingToggleOn && showIncomeAccountAlert && level === 'Corporate'"
        @close="showIncomeAccountAlert = false"
        type="caution"
        :notDismissable="true"
      >
        There are some product(s) with unconfigured gl account.
      </hb-notification>

     <hb-data-table-header v-if="!isOPS && level !== 'Property' && !facilityID" :class="{ 'mt-n1' : !showIncomeAccountAlert }">
        <template v-slot:description>
            This is where you add products and descriptions, set pricing and assign taxability.
        </template>
        <template v-slot:actions>
            <hb-btn small color="secondary" @click="addNewProduct">Add New Product</hb-btn>
        </template>
      </hb-data-table-header>

      <hb-data-table
        v-if="(level == 'Property' || facilityID) && property_id" :class="{'mt-4' : facilityID}"
        :headers="headersOne"
        :items="products"
        @click:row="editItem"
      >
        <template v-slot:item.name="{ item }">
          {{ item.name }}<br />
          <span class="hb-text-light">{{ item.description }}</span>
        </template>
        <template v-slot:item.gl_account_code="{ item }">
          <span v-if="item.gl_account_active == 0" class="pr-1">
          <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
          </span>
          <span :class="{'hb-text-error' : item.gl_account_active == 0}">{{item.gl_account_code}}</span>
        </template>
        <template v-slot:item.gl_account_name="{ item }">
          <span v-if="item.gl_account_active == 0" class="pr-1">
          <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
          </span>
          <span :class="{'hb-text-error' : item.gl_account_active == 0}">{{item.gl_account_name}}</span>
        </template>
        <template v-slot:item.amount_type="{ item }">
          <span class="text-capitalize">{{ item.amount_type }}</span>
        </template>
        <template v-slot:item.price="{ item }">{{ formatPriceByType(item.price, item.amount_type) }}</template>
        <template v-slot:item.taxable="{ item }">
          <span class="icon" v-show="item.taxable">Yes</span>
          <span class="icon" v-show="!item.taxable">No</span>
        </template>
        <template v-slot:item.inventory="{ item }">
          <span v-if="item.has_inventory">{{ item.inventory }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <hb-menu
            options
            align-right
          >
            <v-list>
              <v-list-item @click="editItem(item)" v-if="hasPermission('manage_retail_products')">
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteItem(item)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </hb-menu>
        </template>
      </hb-data-table>

      <hb-empty-state
        v-else-if="(level == 'Property' || facilityID) && !property_id"
        message="Please select a Property to continue."
      >
      </hb-empty-state>

      <hb-data-table
        v-else
        :headers="headersTwo"
        :items="products"
        @click:row="editItem"
      >
      <!-- :items="merchandise" -->
        <template v-slot:item.name="{ item }">
          {{ item.name }}<br />
          <span class="hb-text-light">{{ item.description }}</span>
        </template>
        <template v-slot:item.gl_account_code="{ item }">
          <span v-if="item.gl_account_active == 0" class="pr-1">
          <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
          </span>
          <span :class="{'hb-text-error' : item.gl_account_active == 0}">{{item.gl_account_code}}</span>
        </template>
        <template v-slot:item.gl_account_name="{ item }">
          <span v-if="item.gl_account_active == 0" class="pr-1">
          <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
          </span>
          <span :class="{'hb-text-error' : item.gl_account_active == 0}">{{item.gl_account_name}}</span>
        </template>
        <template v-slot:item.amount_type="{ item }">
          <span class="text-capitalize">{{ item.amount_type }}</span>
        </template>
        <template v-slot:item.price="{ item }">{{ formatPriceByType(item.price, item.amount_type) }}</template>
        <template v-slot:item.taxable="{ item }">
          <span class="icon" v-show="item.taxable">Yes</span>
          <span class="icon" v-show="!item.taxable">No</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <hb-menu
            options
            align-right
          >
            <v-list>
              <v-list-item @click="editItem(item)" v-if="hasPermission('manage_retail_products')">
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteItem(item)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </hb-menu>
        </template>
      </hb-data-table>
    </div>

    <edit-product  v-model="showAdd" v-if="showAdd" productType="product" :isOPS='isOPS' :productLevel="level" :facilityID="facilityID || property_id" @close="closeWindow" @refetch="fetchData" @showSuccess="showSuccessMessage"></edit-product>
    <edit-product v-model="showEdit" v-if="showEdit" productType="product" :isOPS='isOPS' :productLevel="level" :facilityID="facilityID || property_id" :selected="selected" @close="closeWindow" @refetch="fetchData" @showSuccess="showSuccessMessage"></edit-product>

    <hb-modal v-model="showDelete" show-help-link v-if="showDelete" size="medium" :title="!usage.length? 'Delete Product' : 'Cannot Delete Product'" @close="closeWindow" confirmation>
            <template v-slot:content>
                
                <div class="py-4 px-6" v-if="!usage.length">
                   Are you sure you want to delete {{selected.name}}?
                   <br /><br />
                   This action cannot be undone.
                </div>
                <div class="py-4 px-6" v-else>
                   <strong>{{selected.name}} is currently in use in one or more processes so it cannot be deleted.</strong>
                   <br /><br />
                   <p>To delete this product, first remove it from the following processes.</p>
                   
                    <ul>
                        <li v-for="(u, i) in usage" :key="i">{{ u.process | process_type }}: {{ u.name }}</li>
                    </ul>
                </div>
                
            </template>
            <template v-slot:actions>
                <hb-btn color="destructive" :disabled="!!usage.length" @click="deleteConfirm">Delete</hb-btn>
                <span v-show="isLoading($options.name)">
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </template>
        </hb-modal>

    <payments-notification-modal
        v-model="notificationModal"
        notificationType="manage_retail_products"
    >
    </payments-notification-modal>

  </div>
</template>

<script type="text/babel">
    import Modal from '../assets/Modal.vue';
    import EditProduct from './EditProduct.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import settingsTabMixin from '../../mixins/settingsTabMixin.js';
    import {  mapActions, mapGetters } from 'vuex';
	  import { notificationMixin } from "../../mixins/notificationMixin.js";
    import { formatPriceByType } from "../../utils/common";
    import PaymentsNotificationModal from '../payments/PaymentsNotificationModal.vue';

    export default {
        name: "ProductSettings",
        mixins:[settingsTabMixin, notificationMixin ],
        props: {
            level : {
                type: String,
                default: ''
            },
            facilityID: {
                type : String,
                default: ''
            },
            isOPS:{            // Added by BCT Team
            type: Boolean,
            default: false
          }
        }, 
        data() {
            return {
                products:[],
                selected: {},
                showAdd: false,
                showEdit: false,
                usage: [],
                showDelete: false,
                showIncomeAccountAlert: false,
                property_id: '',
                headersOne: [
                    { text: "Product Name", value: "name" },
                    { text: "GL Code", value: "gl_account_code" },
                    { text: "GL Name", value: "gl_account_name" },
                    { text: "Type", value: "amount_type" },
                    { text: "Price", value: "price" },
                    { text: "Taxable", value: "taxable" },
                    { text: "Inventory", value: "inventory" },
                    { text: "", value: "actions", align: "right", sortable: false, width: 10 }
                ],
                headersTwo: [
                    { text: "Product Name", value: "name" },
                    { text: "GL Code", value: "gl_account_code" },
                    { text: "GL Name", value: "gl_account_name" },
                    { text: "Type", value: "amount_type" },
                    { text: "Price", value: "price" },
                    { text: "Taxable", value: "taxable" },
                    { text: "", value: "actions", align: "right", sortable: false, width: 10 }
                ],
                notificationModal: false,
            }
        },
        components:{
            Modal,
            EditProduct,
            Loader,
            Status,
            PaymentsNotificationModal
        },
        created(){
            if (this.level === 'Corporate' || this.$props.facilityID) {
              this.property_id = this.$props.facilityID;
              this.fetchData();
            }
            this.getAccountingSettings();
        },
        watch: {
            property_id(p) {
                this.fetchData();
            }
        },
        computed:{
            ...mapGetters({
                facilityList: 'propertiesStore/filtered',
                accountingSettings: "accountingStore/accountingSettings",
                onboardingData: "onboardingTabsStore/getOnboardingData",
                hasPermission: 'authenticationStore/rolePermission'
            }),
            merchandise(){
                return this.products.filter(p => p.default_type === 'product');
            },
            accountingToggleOn(){
              return Object.keys(this.accountingSettings).length && this.accountingSettings.toggleAccounting == "1"? true : false;
            }
        },
        filters:{
            formatPrice(val){
                if(!val) return '-';
                return "$" + val;
            },
            process_type(process){
                if(process === 'delinquency') return "Delinquency Process";
                if(process === 'template') return "Lease Template"; 
            }
        },
        methods:{
            ...mapActions({
                getAccountingSettings: "accountingStore/getAccountingSettings",
            }),
            initializeData(){
                this.products = [];
            },
            closeWindow(){
                this.showEdit = false;
                this.showDelete = false;
                this.showAdd = false;
                this.selected = {};
            },
            fetchData(){
                let path = this.facilityID || this.level === 'Property' ? api.PROPERTIES + (this.facilityID || this.property_id) + '/products?type=product' : api.PRODUCTS
                api.get(this, path, this.level === 'Corporate' && {
                    search: 'product'
                }).then(r => {
                  this.products = r.products;
                  this.showIncomeAccountAlert = this.products.filter(i => !i.income_account_id).length && this.level === 'Corporate'? true : false;
                }).catch(err => {
                    if (err === 'Not authorized') {
                        this.products = [];
                    }
                });
            },

            editItem(c){
                if (this.hasPermission('manage_retail_products')) {
                  this.selected = c;
                  this.showEdit = true;
                } else {
                  this.notificationModal = true;
                }
            },
            async deleteItem(c){
                this.selected = c;
                let response = await api.get(this, api.PRODUCTS + `${this.selected.id}/usage`); 
                this.usage = response.usage;
                this.showDelete = true;
            },
            async deleteConfirm(){
                if(this.level === 'Property') {
                  await api.delete(this, api.PROPERTIES, `${this.facilityID || this.property_id}/products/${this.selected.id}`);
                } else {
                  await api.delete(this, api.PRODUCTS, this.selected.id);
                }
                
                this.showDelete = false;
                this.fetchData();
                this.selected = {};
            },
            showSuccessMessage(productName, isEdited = false){
              let currentLocalDate = this.$options.filters.formatDateTimeCustom(
                        new Date(),
                        "MMM DD, YYYY @ h:mma."
                    );
                let message = ( isEdited ? "The '" + productName + "' has been updated on " + currentLocalDate : "The '" + productName + "' has been created on " + currentLocalDate);
                //this.successSet(this.$options.name, message);
                this.showMessageNotification({ type: 'success', description: message });
            },
            formatPriceByType,
            addNewProduct() {
              if(this.hasPermission('manage_retail_products')) {
                this.showAdd = true;
              } else {
                this.notificationModal = true;
              }
            },            
        },
        mounted(){
           if(this.isOPS && this.level === 'Property' && this.onboardingData.propertyData.length > 0){
             this.facilityID = this.onboardingData.propertyData[0].property_id;
             this.fetchData()   
          }
 
        }
    }
</script>

<style>
  .subdued {
    color: #a5adb4;
    line-height: 18px;
  }
  .add-role-btn{
    font-size: 15px;
    text-decoration: none;
  }
  .add-role-btn:focus{
    border: none;
  }
  .roles-table {
    background: #FFFFFF;
    box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
    border-radius: 4px;
  }
  .roles-table .table-head{
    background: #FFFFFF;
    /* box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.25); */
    border-bottom: 1px solid #ccc;
  }
  .roles-table .table-head strong{
    color: #474F5A;
  }
  .roles-table .table-row:hover:not(:first-child){
    background: #F9FAFB;
    cursor: pointer;
  }
  .roles-table .table-row:first-child {
    cursor: inherit;
  }
  .roles-table .table-row .table-cell{
    border-bottom: 1px solid #dce8ef;
    color: #000;
  }
  .light-text{
    color: #637381;
  }
  .not-visible{
    visibility: hidden;
  }
  .roles-table .table-row:hover .not-visible{
    visibility: visible;
  }
 
    .ops-add-product-btn {
        height: 40px;
    }
</style>

